@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: sans-serif;
  background-color: #e5e7eb;
}

.ant-table-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 16px;
}
 .ant-table-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
}
 .ant-table-body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 16px;
}

.ant-steps-item-icon{
  line-height: unset !important;
}
.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail:after {
  background-color: #14532d !important;
}
.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background-color: #14532d !important;
}
.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background-color: #14532d !important;
}
.ant-switch-checked .ant-switch-inner{
  margin : -7px 25px 0px 7px !important;
}
.ant-switch-inner{
  margin: -6px 7px 0 25px !important;
}
.ant-switch-checked{
  background-color: #14532d !important;
}

.ant-table-body {
  max-height: 620px !important;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

@media only screen and (max-height: 720px) {
  .leaflet-container{
    height: 380px
  }

  .ant-table-body {
    max-height: 230px !important;
  }
}
